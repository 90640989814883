import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardDeck,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaRedo } from "react-icons/fa";

import hostingImg from "../img/Web_hosting_PNG.png";
import servicesImg from "../img/security_PNG.png";
import peopleImg from "../img/people_PNG.png";
import LibreonHelmet from "../components/libreon-helmet";
import ErrorDiv from "../components/error";
import Utils from "../classes/utils";

export default class Mentionlegales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <LibreonHelmet
          title="Libreon - Mentions légales"
          description="Mentions légales de l'association Libreon"
        />
        <Container className="my-big">
          <Container className="mt-5 mb-3">
            <h1 className="mb-3">
              <span className="l-gradient">Mentions légales</span>
            </h1>
            <p className="mb-1">Association Libreon</p>
            <p className="mb-1">73 rue Alphonse Guérin</p>
            <p className="mb-1">35000 Rennes</p>
            <hr />
            <p className="mb-1">Président : Xavier Garnier</p>
            <p className="">Trésorière : Hélène Mornet</p>

            <p className="mb-1">Conseil d'administration :</p>
            <ul>
              <li>Matéo Boudet</li>
              <li>Jérémy Le Coz</li>
              <li>Xavier Garnier</li>
              <li>Hélène Mornet</li>
            </ul>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://cloud.libreon.fr/s/qqoE59yiXHb8Kq9"
            >
              Status
            </a>
          </Container>
        </Container>
      </>
    );
  }
}
