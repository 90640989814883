import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import logo from "../img/logo_libreon_chatons.png";

export default class LibreonNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      transparent: true,
    };
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      this.setState({ transparent: window.scrollY < 100 ? true : false });
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  close() {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const className = this.state.transparent
      ? " navbar-dark shadow-sm fixed-top transparent-dark"
      : " navbar-dark shadow-sm fixed-top dark";

    return (
      <div>
        <Navbar className={className} scrolling expand="md">
          <Container>
            <NavbarBrand>
              <Link onClick={this.close} to="/">
                <img alt="xgaia.fr" src={logo} height="50px" />
              </Link>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link
                    onClick={this.close}
                    className="nav-link"
                    to="/association"
                  >
                    L'association
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    onClick={this.close}
                    className="nav-link"
                    to="/services"
                  >
                    Les services{" "}
                  </Link>
                </NavItem>
                {/*<NavItem><Link onClick={this.close} className="nav-link" to="/hosting">L'hébergement</Link></NavItem>*/}
                <NavItem>
                  <Link onClick={this.close} className="nav-link" to="/contact">
                    Contact
                  </Link>
                </NavItem>
                <NavItem>
                  <Link onClick={this.close} className="nav-link" to="/account">
                    Compte
                  </Link>
                </NavItem>
                <NavItem>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="nav-link"
                    href="https://blog.libreon.fr"
                  >
                    Blog
                  </a>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
