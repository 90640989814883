import React from "react";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaRedo } from "react-icons/fa";

import LibreonHelmet from "../components/libreon-helmet";
import ErrorDiv from "../components/error";
import Utils from "../classes/utils";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      error: false,
      success: false,
      waiting: false,
      errorMessage: "",
      successMessage: "",
      captcha: {
        image: "",
        solver: "",
        signedSolver: "",
        input: "",
        solved: false,
      },
    };
    this.utils = new Utils();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
    this.renewCaptcha = this.renewCaptcha.bind(this);
  }

  componentDidMount() {
    this.getCaptcha();
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleCaptchaChange(event) {
    const solved =
      this.state[event.target.id].solver ===
      sha1(event.target.value.toLowerCase());

    this.setState({
      [event.target.id]: update(this.state[event.target.id], {
        input: { $set: event.target.value },
        solved: { $set: solved },
      }),
    });
  }

  renewCaptcha(event) {
    this.getCaptcha();
    event.preventDefault();
  }

  getCaptcha() {
    const requestUrl = this.props.config.apiUrl + "/captcha";
    axios
      .get(requestUrl)
      .then((response) => {
        this.setState({
          captcha: {
            image: response.data.captcha,
            solver: response.data.solver,
            signedSolver: response.data.signedSolver,
            input: "",
            solved: false,
          },
        });
      })
      .catch((error) => {
        let message;
        if (error.response) {
          // manage errors
        } else if (error.request) {
          console.log("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.log("Error", error);
        }
        this.setState({
          error: true,
          waiting: false,
          errorMessage: message,
        });
      });
  }

  hashCode(s) {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  validateCaptcha(input, hash) {
    return hash === sha1(input.toLowerCase());
  }

  validateForm() {
    return (
      this.state.name.length > 0 &&
      this.state.message.length > 0 &&
      this.utils.validateEmail(this.state.email) &&
      this.validateCaptcha(
        this.state.captcha.input,
        this.state.captcha.solver
      ) &&
      !this.state.waiting
    );
  }

  handleSubmit(event) {
    const requestUrl = this.props.config.apiUrl + "/contact";
    const data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      captcha: {
        input: this.state.captcha.input,
        signedSolver: this.state.captcha.signedSolver,
      },
    };
    this.setState({
      waiting: true,
    });
    axios
      .post(requestUrl, data)
      .then((response) => {
        console.log(requestUrl, data);
        this.setState(
          {
            error: false,
            success: true,
            waiting: false,
            successMessage:
              "Votre message a bien été envoyé. Vous allez recevoir une réponse dans les plus brefs délais.",
            name: "",
            email: "",
            message: "",
          },
          () => {
            this.getCaptcha();
          }
        );
      })
      .catch((error) => {
        let message;
        if (error.response) {
          // manage errors
          message = error.response.data.message;
        } else if (error.request) {
          console.log("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.log("Error", error);
        }
        this.setState({
          error: true,
          waiting: false,
          errorMessage: message,
        });
      });
    event.preventDefault();
  }

  render() {
    return (
      <>
        <LibreonHelmet
          title="Libreon - Contact"
          description="Contactez Libreon afin d'obtenir plus d'information sur les services proposé"
        />
        <Container className="my-big">
          {/* Contact form */}
          <Col className="" md={8}>
            <h2 className="mb-4">
              <span className="l-gradient">
                Une question ? Contactez nous !
              </span>
            </h2>
            <h3 className="white">Matrix</h3>
            <div className="mb-4">
              <p>
                Rejoignez notre espace matrix et venez discuter avec les
                utilisateur·trice·s, les adhérent·e·s et les
                administrateur·trice·s de l'association.
              </p>
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href="https://matrix.to/#/#libreon:libreon.fr"
                size="sm"
                className="l-gradient-bg"
              >
                Rejoindre l'espace Matrix
              </Button>
            </div>
            <h3 className="white">Formulaire de contact</h3>
            <p>
              Une question ? remplissez le formulaire de contact suivant et
              l'équipe vous répondra dans les meilleurs délais !
            </p>
            <Card className="shadow-card">
              <Container className="py-5">
                <Form>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          onChange={this.handleChange}
                          type="text"
                          name="name"
                          id="name"
                          value={this.state.name}
                          placeholder="Votre nom"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          onChange={this.handleChange}
                          type="email"
                          name="email"
                          id="email"
                          value={this.state.email}
                          placeholder="Votre email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Input
                    onChange={this.handleChange}
                    type="textarea"
                    name="text"
                    id="message"
                    value={this.state.message}
                    placeholder="Votre message"
                    style={{ height: "150px" }}
                  />

                  <FormGroup>
                    <Row className="mt-2">
                      <Col className="pr-1 mb-1" md={2}>
                        <img
                          className="captcha"
                          height="36px"
                          src={
                            "data:image/jpeg;base64," + this.state.captcha.image
                          }
                        />
                      </Col>
                      <Col
                        className=" mb-1"
                        style={{ "text-align": "left" }}
                        md={3}
                      >
                        <Input
                          invalid={
                            !this.state.captcha.solved &&
                            this.state.captcha.input.length >= 5
                          }
                          valid={this.state.captcha.solved}
                          onChange={this.handleCaptchaChange}
                          value={this.state.captcha.input}
                          type="text"
                          name="captcha"
                          id="captcha"
                          placeholder="Captcha"
                        />
                      </Col>
                      <Col
                        onClick={this.renewCaptcha}
                        className="pr-1"
                        xs={4}
                        md={2}
                      >
                        <FaRedo className="redo" />
                      </Col>
                    </Row>
                  </FormGroup>

                  <div className="mt-3">
                    <Button
                      onClick={this.handleSubmit}
                      disabled={
                        this.state.waiting ? true : !this.validateForm()
                      }
                      className="btn-nahibu-simple"
                    >
                      Envoyer
                    </Button>
                  </div>
                  <div className="mt-2">
                    <ErrorDiv
                      error={this.state.error}
                      message={this.state.errorMessage}
                    />
                    {this.state.success ? (
                      <Alert color="success">{this.state.successMessage}</Alert>
                    ) : null}
                  </div>
                </Form>
              </Container>
            </Card>
          </Col>
        </Container>
      </>
    );
  }
}
