import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaRedo } from "react-icons/fa";

import hostingImg from "../img/Web_hosting_PNG.png";
import servicesImg from "../img/security_PNG.png";
import peopleImg from "../img/people_PNG.png";
import LibreonHelmet from "../components/libreon-helmet";
import ErrorDiv from "../components/error";
import Utils from "../classes/utils";
import ServicesList from "../data/services.json";

export default class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      username: "",
      email: "",
      optionalMessage: "",
      memberRequest: false,
      newsletterRequest: false,
      acceptCgu: false,
      error: false,
      success: false,
      waiting: false,
      errorMessage: "",
      successMessage: "",
      captcha: {
        image: "",
        solver: "",
        signedSolver: "",
        input: "",
        solved: false,
      },
    };
    this.utils = new Utils();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
    this.renewCaptcha = this.renewCaptcha.bind(this);
  }

  componentDidMount() {
    this.getCaptcha();
  }

  handleChange(event) {
    this.setState({
      [event.target.id]:
        event.target.id === "username"
          ? this.utils.stripUsername(event.target.value)
          : event.target.value,
    });
  }

  handleChangeChk(event) {
    this.setState({
      [event.target.id]: !this.state[event.target.id],
    });
  }

  handleCaptchaChange(event) {
    const solved =
      this.state[event.target.id].solver ===
      sha1(event.target.value.toLowerCase());

    this.setState({
      [event.target.id]: update(this.state[event.target.id], {
        input: { $set: event.target.value },
        solved: { $set: solved },
      }),
    });
  }

  renewCaptcha(event) {
    this.getCaptcha();
    event.preventDefault();
  }

  getCaptcha() {
    const requestUrl = this.props.config.apiUrl + "/captcha";
    axios
      .get(requestUrl)
      .then((response) => {
        this.setState({
          captcha: {
            image: response.data.captcha,
            solver: response.data.solver,
            signedSolver: response.data.signedSolver,
            input: "",
            solved: false,
          },
        });
      })
      .catch((error) => {
        let message;
        if (error.response) {
          // manage errors
        } else if (error.request) {
          console.log("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.log("Error", error);
        }
        this.setState({
          error: true,
          waiting: false,
          errorMessage: message,
        });
      });
  }

  hashCode(s) {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  validateCaptcha(input, hash) {
    return hash === sha1(input.toLowerCase());
  }

  validateForm() {
    return (
      this.state.fname.length > 0 &&
      this.state.lname.length > 0 &&
      this.utils.validateUsername(this.state.username) &&
      this.utils.validateEmail(this.state.email) &&
      this.state.acceptCgu &&
      this.validateCaptcha(
        this.state.captcha.input,
        this.state.captcha.solver
      ) &&
      !this.state.waiting
    );
  }

  handleSubmit(event) {
    const requestUrl = this.props.config.apiUrl + "/account-request";
    const data = {
      fname: this.state.fname,
      lname: this.state.lname,
      username: this.state.username,
      email: this.state.email,
      optionalMessage: this.state.optionalMessage,
      memberRequest: this.state.memberRequest,
      newsletterRequest: this.state.newsletterRequest,
      captcha: {
        input: this.state.captcha.input,
        signedSolver: this.state.captcha.signedSolver,
      },
    };
    this.setState({
      waiting: true,
    });
    axios
      .post(requestUrl, data)
      .then((response) => {
        console.log(requestUrl, data);
        this.setState(
          {
            error: false,
            success: true,
            waiting: false,
            successMessage:
              "Votre demande a bien été envoyée. Vous allez recevoir une réponse dans les plus brefs délais.",
            fname: "",
            lname: "",
            username: "",
            email: "",
            optionalMessage: "",
            memberRequest: false,
            newsletterRequest: false,
            acceptCgu: false,
          },
          () => {
            this.getCaptcha();
          }
        );
      })
      .catch((error) => {
        let message;
        if (error.response) {
          // manage errors
          message = error.response.data.message;
        } else if (error.request) {
          console.log("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.log("Error", error);
        }
        this.setState({
          error: true,
          waiting: false,
          errorMessage: message,
        });
      });
    event.preventDefault();
  }

  render() {
    const numberOfServicesToDisplay = 3;
    return (
      <>
        <LibreonHelmet
          title="Libreon - Demande de compte"
          description="Demandez un compte Libreon afin d'avoir accès aux services"
        />

        <Container className="my-big">
          {/* Contact form */}
          <Col md={8}>
            <div className="mb-4">
              <h2 className="mb-3">
                <span className="l-gradient">Demande de compte Libreon</span>
              </h2>
              <p>
                Pour utiliser certains services (
                {ServicesList.authenticated
                  .slice(0, numberOfServicesToDisplay)
                  .map((service, i) => (
                    <span key={i}>
                      <a href={service.url} target="_blank" rel="noreferrer">
                        {service.title}
                      </a>
                      {i !== numberOfServicesToDisplay - 1 ? ", " : ""}
                    </span>
                  ))}
                ), un compte Libreon est nécessaire. Remplissez le formulaire
                suivant, votre demande sera traitée manuellement par
                l'association.
              </p>
              <p>
                Vous receverez ensuite un email confirmant la création de votre
                compte. La marche à suivre pour activer votre compte et
                commencer à utiliser les services vous sera expliquée.
              </p>
            </div>
            <Card>
              <Container className="py-5">
                <Form>
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <Label for="fname">Prénom</Label>
                        <Input
                          valid={
                            this.state.fname.length > 0 && this.state.fname != 0
                          }
                          invalid={
                            this.state.fname.length > 0 && this.state.fname == 0
                          }
                          onChange={this.handleChange}
                          value={this.state.fname}
                          type="text"
                          name="fname"
                          id="fname"
                          placeholder="Homer"
                        />
                      </Col>
                      <Col md={6}>
                        <Label for="lname">Nom</Label>
                        <Input
                          valid={
                            this.state.lname.length > 0 && this.state.lname != 0
                          }
                          invalid={
                            this.state.lname.length > 0 && this.state.lname == 0
                          }
                          onChange={this.handleChange}
                          value={this.state.lname}
                          type="text"
                          name="lname"
                          id="lname"
                          placeholder="Simpson"
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <Label for="username">Username</Label>
                        <Input
                          valid={
                            this.state.username.length > 0 &&
                            this.utils.validateUsername(this.state.username)
                          }
                          invalid={
                            this.state.username.length > 0 &&
                            !this.utils.validateUsername(this.state.username)
                          }
                          onChange={this.handleChange}
                          value={this.state.username}
                          type="text"
                          name="username"
                          id="username"
                          placeholder="chunkylover53"
                        />
                        <FormFeedback>
                          username invalide (3 caractères alphanumeriques
                          minimum)
                        </FormFeedback>
                      </Col>
                      <Col md={6}>
                        <Label for="email">Email</Label>
                        <Input
                          valid={
                            this.state.email.length > 0 &&
                            this.utils.validateEmail(this.state.email)
                          }
                          invalid={
                            this.state.email.length > 0 &&
                            !this.utils.validateEmail(this.state.email)
                          }
                          onChange={this.handleChange}
                          value={this.state.email}
                          type="email"
                          name="email"
                          id="email"
                          placeholder="chunkylover53@aol.com"
                        />
                        <FormFeedback>email invalide</FormFeedback>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label for="optionalMessage">
                      Un petit mot (facultatif) ?
                    </Label>
                    <Input
                      type="textarea"
                      name="optionalMessage"
                      id="optionalMessage"
                      onChange={this.handleChange}
                      value={this.state.optionalMessage}
                      placeholder="Racontez-nous ce qui vous amène ici et comment vous avez connu Libreon"
                    />
                  </FormGroup>

                  <FormGroup className="mb-2" check>
                    <Label check>
                      <Input
                        onChange={this.handleChangeChk}
                        id="memberRequest"
                        checked={this.state.memberRequest}
                        type="checkbox"
                      />{" "}
                      Je souhaite adhérer à l'association Libreon (
                      <Link to="/association">en savoir plus</Link>)
                    </Label>
                  </FormGroup>

                  <FormGroup className="mb-2" check>
                    <Label check>
                      <Input
                        onChange={this.handleChangeChk}
                        id="newsletterRequest"
                        checked={this.state.newsletterRequest}
                        type="checkbox"
                      />{" "}
                      Je souhaite m'inscrire à la newsletter (nouvelles de
                      l'association et articles de{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://blog.libreon.fr"
                      >
                        blog
                      </a>
                      )
                    </Label>
                  </FormGroup>

                  <FormGroup className="mb-2" check>
                    <Label check>
                      <Input
                        required={true}
                        onChange={this.handleChangeChk}
                        id="acceptCgu"
                        checked={this.state.acceptCgu}
                        type="checkbox"
                      />{" "}
                      J'ai lu et j'accepte les{" "}
                      <Link to="/cgu">conditions d'utilisations</Link>
                    </Label>
                  </FormGroup>

                  <FormGroup>
                    <Row className="mt-2">
                      <Col className="pr-1 mb-1" md={2}>
                        <img
                          className="captcha"
                          height="36px"
                          src={
                            "data:image/jpeg;base64," + this.state.captcha.image
                          }
                        />
                      </Col>
                      <Col
                        className="mb-1"
                        style={{ "text-align": "left" }}
                        xs={4}
                        md={4}
                      >
                        <Input
                          invalid={
                            !this.state.captcha.solved &&
                            this.state.captcha.input.length >= 5
                          }
                          valid={this.state.captcha.solved}
                          onChange={this.handleCaptchaChange}
                          value={this.state.captcha.input}
                          type="text"
                          name="captcha"
                          id="captcha"
                          placeholder="Captcha"
                        />
                      </Col>
                      <Col
                        onClick={this.renewCaptcha}
                        className="pr-1"
                        xs={4}
                        md={2}
                      >
                        <FaRedo className="redo" />
                      </Col>
                    </Row>
                  </FormGroup>

                  <div className="mt-3">
                    <Button
                      onClick={this.handleSubmit}
                      disabled={
                        this.state.waiting ? true : !this.validateForm()
                      }
                      className="btn-nahibu-simple"
                    >
                      Envoyer
                    </Button>
                  </div>
                  <div className="mt-2">
                    <ErrorDiv
                      error={this.state.error}
                      message={this.state.errorMessage}
                    />
                    {this.state.success ? (
                      <Alert color="success">{this.state.successMessage}</Alert>
                    ) : null}
                  </div>
                </Form>
              </Container>
            </Card>
          </Col>
        </Container>
      </>
    );
  }
}
