import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardDeck,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaRedo } from "react-icons/fa";

import hostingImg from "../img/Web_hosting_PNG.png";
import servicesImg from "../img/security_PNG.png";
import peopleImg from "../img/people_PNG.png";
import LibreonHelmet from "../components/libreon-helmet";
import ErrorDiv from "../components/error";
import Utils from "../classes/utils";

export default class Association extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      error: false,
      success: false,
      waiting: false,
      errorMessage: "",
      successMessage: "",
      newsletterRequest: false,
      captcha: {
        image: "",
        solver: "",
        signedSolver: "",
        input: "",
        solved: false,
      },
    };
    this.utils = new Utils();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
    this.renewCaptcha = this.renewCaptcha.bind(this);
  }

  componentDidMount() {
    this.getCaptcha();
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleChangeChk(event) {
    this.setState({
      [event.target.id]: !this.state[event.target.id],
    });
  }

  handleCaptchaChange(event) {
    const solved =
      this.state[event.target.id].solver ===
      sha1(event.target.value.toLowerCase());

    this.setState({
      [event.target.id]: update(this.state[event.target.id], {
        input: { $set: event.target.value },
        solved: { $set: solved },
      }),
    });
  }

  renewCaptcha(event) {
    this.getCaptcha();
    event.preventDefault();
  }

  getCaptcha() {
    const requestUrl = this.props.config.apiUrl + "/captcha";
    axios
      .get(requestUrl)
      .then((response) => {
        this.setState({
          captcha: {
            image: response.data.captcha,
            solver: response.data.solver,
            signedSolver: response.data.signedSolver,
            input: "",
            solved: false,
          },
        });
      })
      .catch((error) => {
        let message;
        if (error.response) {
          // manage errors
        } else if (error.request) {
          console.log("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.log("Error", error);
        }
        this.setState({
          error: true,
          waiting: false,
          errorMessage: message,
        });
      });
  }

  hashCode(s) {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  validateCaptcha(input, hash) {
    return hash === sha1(input.toLowerCase());
  }

  validateForm() {
    return (
      this.state.fname.length > 0 &&
      this.state.lname.length > 0 &&
      this.utils.validateEmail(this.state.email) &&
      this.validateCaptcha(
        this.state.captcha.input,
        this.state.captcha.solver
      ) &&
      !this.state.waiting
    );
  }

  handleSubmit(event) {
    const requestUrl = this.props.config.apiUrl + "/member-request";
    const data = {
      fname: this.state.fname,
      lname: this.state.lname,
      email: this.state.email,
      newsletterRequest: this.state.newsletterRequest,
      captcha: {
        input: this.state.captcha.input,
        signedSolver: this.state.captcha.signedSolver,
      },
    };
    this.setState({
      waiting: true,
    });
    axios
      .post(requestUrl, data)
      .then((response) => {
        console.log(requestUrl, data);
        this.setState(
          {
            error: false,
            success: true,
            waiting: false,
            successMessage:
              "Votre demande a bien été envoyé. Vous allez recevoir une réponse dans les plus brefs délais.",
            fname: "",
            lname: "",
            email: "",
            newsletterRequest: false,
          },
          () => {
            this.getCaptcha();
          }
        );
      })
      .catch((error) => {
        let message;
        if (error.response) {
          // manage errors
          message = error.response.data.message;
        } else if (error.request) {
          console.log("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.log("Error", error);
        }
        this.setState({
          error: true,
          waiting: false,
          errorMessage: message,
        });
      });
    event.preventDefault();
  }

  render() {
    return (
      <>
        <LibreonHelmet
          title="Libreon - L'association"
          description="Libreon est une association rennaise proposant un ensemble de services basés sur des logiciels libres et open-sources"
        />
        <Container className="my-big">
          <div className="mb-5">
            <h1 className="">
              <span className="l-gradient">Libreon</span>
            </h1>
            <h4 className="white">Reprenez le contrôle de vos données</h4>
            <Row>
              <Col md={8}>
                <div className="my-5">
                  {/* Intro */}
                  <p>
                    Libreon est une{" "}
                    <a
                      href="https://www.journal-officiel.gouv.fr/associations/detail-annonce/associations_b/20210003/607"
                      target="_blank"
                      rel="noreferrer"
                    >
                      association
                    </a>{" "}
                    rennaise promouvant l'utilisation de logiciels libres en
                    alternative aux services propriétaires détenus par de
                    grandes entreprises.
                  </p>
                  {/* Hébergement de services */}
                  <p>
                    Pour cela, Libreon propose un ensemble de services
                    permettant d'héberger vos données. Ces services sont basée
                    sur des logiciels libres.
                  </p>
                  {/* Ethique */}
                  <p>
                    Libreon ne revend pas vos données, et ne se les approprie
                    pas. Vous restez propriétaire des données que vous hébergez
                    sur la plateforme.
                  </p>
                  {/* Chatons */}
                  <p>
                    Libreon est membre des{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://chatons.org"
                    >
                      CHATONS
                    </a>
                    , et respecte la{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://chatons.org/fr/charte-et-manifeste"
                    >
                      charte du collectif
                    </a>
                    .
                  </p>
                </div>
              </Col>
              <Col className="center" md={4}>
                <LazyLoadImage
                  className=""
                  alt="l'association"
                  src={servicesImg}
                  width="90%"
                />
              </Col>
            </Row>
          </div>

          <hr className="grey-hr" />

          <div className="my-5">
            <h2 className="white">Libreon, c'est :</h2>
            <CardDeck className="my-5">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Les services</CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 text-muted">
                    Reprenez le contrôle
                  </CardSubtitle>
                  <CardText>
                    Hébergement et partage de données, discussions instantanées,
                    Libreon c'est un ensemble de services gratuits,
                    exclusivement basé sur des logiciels libres.
                  </CardText>
                  <Link to="/services">
                    <Button size="sm" className="l-gradient-bg">
                      Voir les services
                    </Button>
                  </Link>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Le blog</CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 text-muted">
                    Actualité et mode d'emploi
                  </CardSubtitle>
                  <CardText>
                    Le blog est le canal d'information de Libreon. Retrouvez les
                    nouvelles de l'association et également des tutoriels pour
                    apprendre à utliser les services.
                  </CardText>
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://blog.libreon.fr"
                    size="sm"
                    className="l-gradient-bg"
                  >
                    Consulter le blog
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Et d'autres projets</CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 text-muted">
                    Rejoignez nous !
                  </CardSubtitle>
                  <CardText>
                    Libreon est une jeune association avec de nombreux projets
                    en développement. Si vous souhaitez nous aider, n'hésitez
                    pas à nous conctacter pour en discuter.
                  </CardText>
                  <Link to="/contact">
                    <Button size="sm" className="l-gradient-bg">
                      Participer aux projets
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </CardDeck>
          </div>

          <hr className="grey-hr" />

          <div className="my-5">
            <h2 className="white">Participer et soutenir Libreon</h2>
            <div className="my-5">
              <Row>
                <Col md={6} className="my-2">
                  <p>
                    Les <Link to="/infra">coûts de fonctionnement</Link> de
                    Libreon sont exclusivement couverts par les cotisations des
                    adhérent·e·s et les dons.
                  </p>
                  <p>
                    Si vous appreciez utiliser les services, que vous soutenez
                    nos valeurs, ou que vous voulez apporter votre contribution,
                    nous vous invitons à adhérer à l'association via le
                    formulaire ci contre. Le montant de l'adhésion annuelle est
                    de 12€.
                  </p>
                  <p>
                    En tant qu'adhérent·e, vous pouvez devenir un·e membre
                    actif/ve de l'association. Si vous êtes intéréssé·e,
                    contactez nous via <Link to="/contact">ce formulaire</Link>.
                    nous vous recontacterons afin de vous présenter les projets
                    de l'association.
                  </p>
                  <p>
                    Si vous souhaitez seulement sountenir financièrement
                    l'association sans y adhérer, vous pouvez également faire un
                    don récurent via la plateforme associative{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://liberapay.com/Libreon/donate"
                    >
                      Liberapay
                    </a>
                    .
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://liberapay.com/Libreon/donate"
                  >
                    <img
                      alt="Donate using Liberapay"
                      src="https://liberapay.com/assets/widgets/donate.svg"
                    />
                  </a>
                </Col>

                <Col md={6} className="my-2">
                  <Card>
                    <Container className="py-3">
                      <Form>
                        <FormGroup>
                          <Row>
                            <Col md={6}>
                              <Label for="fname">Prénom</Label>
                              <Input
                                onChange={this.handleChange}
                                value={this.state.fname}
                                type="text"
                                name="fname"
                                id="fname"
                                placeholder="Prénom"
                              />
                            </Col>
                            <Col md={6}>
                              <Label for="lname">Nom</Label>
                              <Input
                                onChange={this.handleChange}
                                value={this.state.lname}
                                type="text"
                                name="lname"
                                id="lname"
                                placeholder="Nom"
                              />
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            onChange={this.handleChange}
                            value={this.state.email}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="email"
                          />
                        </FormGroup>

                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                              onChange={this.handleChangeChk}
                              id="newsletterRequest"
                              checked={this.state.newsletterRequest}
                              type="checkbox"
                            />{" "}
                            Je souhaite m'inscrire à la newsletter Libreon
                          </Label>
                        </FormGroup>

                        <FormGroup>
                          <Row className="mt-2">
                            <Col className="pr-1 mb-1" md={3}>
                              <img
                                className="captcha"
                                height="36px"
                                src={
                                  "data:image/jpeg;base64," +
                                  this.state.captcha.image
                                }
                              />
                            </Col>
                            <Col
                              className="mb-1"
                              style={{ "text-align": "left" }}
                              md={3}
                            >
                              <Input
                                invalid={
                                  !this.state.captcha.solved &&
                                  this.state.captcha.input.length >= 5
                                }
                                valid={this.state.captcha.solved}
                                onChange={this.handleCaptchaChange}
                                value={this.state.captcha.input}
                                type="text"
                                name="captcha"
                                id="captcha"
                                placeholder="Captcha"
                              />
                            </Col>
                            <Col
                              onClick={this.renewCaptcha}
                              className="pr-1"
                              xs={4}
                              md={2}
                            >
                              <FaRedo className="redo" />
                            </Col>
                          </Row>
                        </FormGroup>

                        <div className="mt-3">
                          <Button
                            onClick={this.handleSubmit}
                            disabled={
                              this.state.waiting ? true : !this.validateForm()
                            }
                            className=""
                          >
                            Adhérer
                          </Button>
                        </div>
                        <div className="mt-2">
                          <ErrorDiv
                            error={this.state.error}
                            message={this.state.errorMessage}
                          />
                          {this.state.success ? (
                            <Alert color="success">
                              {this.state.successMessage}
                            </Alert>
                          ) : null}
                        </div>
                      </Form>
                    </Container>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
}
