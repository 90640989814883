import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardDeck,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaRedo } from "react-icons/fa";

import hostingImg from "../img/Web_hosting_PNG.png";
import servicesImg from "../img/security_PNG.png";
import peopleImg from "../img/people_PNG.png";
import LibreonHelmet from "../components/libreon-helmet";
import ErrorDiv from "../components/error";
import Utils from "../classes/utils";
import ServicesList from "../data/services.json";

import { FaExclamationTriangle } from "react-icons/fa";

export default class Infra extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <LibreonHelmet
          title="Libreon - L'infrastructure"
          description="Infrastructure de Libreon"
        />
        <Container className="my-big">
          <Container className="mt-5 mb-3">
            <div className="anchor" id="services"></div>
            <h1 className="center mb-3">
              <span className="l-gradient">L'infrastructure</span>
            </h1>
            <h2 className="white">Coût</h2>
            <p>
              L'association Libreon prend en charge l'ensemble des coûts de
              fonctionnement. Si vous souhaitez contribuer financièrement, vous
              pouvez <Link to="/association">adhérer</Link> à l'association.
            </p>
            <ul>
              <li>Location du serveur principal : 264,84€ / an</li>
              <li>Location du serveur de sauvegarde : 72,00€ / an</li>
              <li>Nom de domaine : 8,39€ / an</li>
              <li>
                Service de messagerie (
                <a target="_blank" rel="noreferrer" href="https://migadu.com">
                  Migadu
                </a>
                ) : 37,41€ / an
              </li>
            </ul>

            <h2 className="white">Administation</h2>

            <p>L’équipe d'administration est composées de :</p>
            <ul>
              <li>Jérémy</li>
              <li>Matéo</li>
              <li>Noé</li>
              <li>Pierre</li>
              <li>Xavier</li>
            </ul>
            <p>L'équipe à un accès root sur l’ensemble de l’infrastructure.</p>
            <p>
              L'ensemble des membres de l'équipe d'administration ont signés la{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://cloud.libreon.fr/s/wMtZGQf3TK7XqwC"
              >
                charte des administrateur·trice·s de Libreon
              </a>
              .
            </p>

            <h2 className="white">Développement</h2>
            <p>Libreon développe les sites et services suivants :</p>

            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://gitlab.libreon.fr/Libreon/front"
                >
                  Libreon-front
                </a>{" "}
                : le site{" "}
                <a target="_blank" rel="noreferrer" href="https://libreon.fr">
                  libreon.fr
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://gitlab.libreon.fr/Libreon/api"
                >
                  Libreon-api
                </a>{" "}
                : l'api de{" "}
                <a target="_blank" rel="noreferrer" href="https://libreon.fr">
                  libreon.fr
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://gitlab.libreon.fr/Libreon/version-checker"
                >
                  version-checker
                </a>{" "}
                : applicaton web pour vérifier si nos services sont à jour
              </li>
            </ul>
            <p>
              Les contributeur·trice·s suivant·e·s ont participé·e·s aux
              développements de ces projets :
            </p>

            <ul>
              <li>Laurie</li>
              <li>Xavier</li>
            </ul>

            <h2 className="white">Hardware</h2>

            <p>L'infrastructure de Libreon repose sur deux machines :</p>
            <ul>
              <li>
                Le serveur principal, sur lequel sont hébergés l'ensemble des
                services, sites et données.
              </li>
              <li>
                Le serveur de sauvegarde, sur lequel sont stockés les
                sauvegardes des données et configurations du serveur principal.
              </li>
            </ul>

            <p>
              Le serveur principal est un{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.scaleway.com/fr/dedibox/start/start-2-m-sata/"
              >
                Start-2-M-SATA
              </a>{" "}
              hébergés chez{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.scaleway.com/fr/"
              >
                Scaleway
              </a>{" "}
              au sein du datacenter DC-2 à Paris, en France. Ce datacenter est
              alimenté à{" "}
              <a
                href="https://www.scaleway.com/fr/leadership-environnemental/"
                target="_blank"
                rel="noreferrer"
              >
                100% en énergie renouvelable (hydrolique)
              </a>
              . Le serveur tourne avec le système d'exploitation libre Debian
              11.
            </p>
            <p>
              Le serveur de sauvegarde est un{" "}
              <a href="" target="_blank" rel="noreferrer">
                VPS Cloud
              </a>{" "}
              hébergé chez{" "}
              <a
                href="https://pulseheberg.com/"
                target="_blank"
                rel="noreferrer"
              >
                Pulseheberg
              </a>{" "}
              au sein du datacenter NCE01 à Nice, en France. Il tourne avec le
              système d'expoitation libre Debian 11.
            </p>

            <h2 className="white">Software</h2>
            <p>
              Tous les <Link to="/services">services</Link>, ainsi que le
              serveur web, sont déployés dans des conteneurs dockers. Les
              fichiers de configuration sont consultables sur notre instance{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://gitlab.libreon.fr/Libreon/compose"
              >
                GitLab
              </a>
            </p>
            <p>
              Le service de messagerie est fourni par{" "}
              <a target="_blank" rel="noreferrer" href="https://migadu.com">
                Migadu
              </a>
              , L'entreprise s'engage à ne pas accéder aux données. Leur
              solution est entièrement basé sur des logiciels libres. C'est une
              entreprise Suisse.
            </p>
            <p></p>

            <h2 className="white">Sauvegarde</h2>
            <p>
              La sauvegarde est réalisée toutes les nuits à 3h du matin avec le
              logiciel{" "}
              <a
                href="https://www.borgbackup.org/"
                target="_blank"
                rel="noreferrer"
              >
                Borg
              </a>
              . Sont conservés les 7 derniers jours et les 4 dernières semaines.
              La durée de rétention est donc de 1 mois. Le script de sauvegarde
              est consultable{" "}
              <a
                href="https://gitlab.libreon.fr/libreon/compose/-/blob/master/backup.sh"
                target="_blank"
                rel="noreferrer"
              >
                ici
              </a>
              .
            </p>

            <h2 className="white">Sources</h2>
            <p>
              L'ensemble des <Link to="/services">services</Link> et outils
              utilisés par Libreon sont des logiciels libres et open-sources.
              Les sources sont consultables en suivant les liens suivants :
            </p>
            <ul>
              <li>Le site Libreon</li>
              <ul>
                <li>
                  Front:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://git.libreon.fr/Libreon/front"
                  >
                    git.libreon.fr/Libreon/front
                  </a>
                </li>
                <li>
                  API :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://git.libreon.fr/Libreon/api"
                  >
                    git.libreon.fr/Libreon/api
                  </a>
                </li>
              </ul>
              <li>Le blog Libreon</li>
              <ul>
                <li>
                  Ghost :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/TryGhost/Ghost"
                  >
                    github.com/TryGhost/Ghost
                  </a>
                </li>
                <li>
                  Isso :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/posativ/isso"
                  >
                    github.com/posativ/isso
                  </a>
                </li>
              </ul>
              <li>Gestion des comptes LDAP</li>
              <ul>
                <li>
                  Ldap User Manager:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/wheelybird/ldap-user-manager"
                  >
                    github.com/wheelybird/ldap-user-manager
                  </a>
                </li>
                <li>
                  Self Service Password :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/ltb-project/self-service-password"
                  >
                    github.com/ltb-project/self-service-password
                  </a>
                </li>
              </ul>
              <li>Les services authentifiés sur le LDAP</li>
              <ul>
                {ServicesList.authenticated.map((service) => (
                  <li>
                    {service.title}:{" "}
                    <a target="_blank" rel="noreferrer" href={service.git}>
                      {service.git.split("//").at(-1)}
                    </a>
                  </li>
                ))}
              </ul>
              <li>Les autres services</li>
              <ul>
                {ServicesList.freeAccess.map((service) => (
                  <li>
                    {service.title}:{" "}
                    <a target="_blank" rel="noreferrer" href={service.git}>
                      {service.git.split("//").at(-1)}
                    </a>
                  </li>
                ))}
              </ul>
              <li>Le logiciel de sauvegarde</li>
              <ul>
                <li>
                  Borg :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/borgbackup/borg"
                  >
                    github.com/borgbackup/borg
                  </a>
                </li>
              </ul>
              <li>Les outils de mailing (envoi des newsletters)</li>
              <ul>
                <li>
                  Listmonk :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/knadh/listmonk"
                  >
                    github.com/knadh/listmonk
                  </a>
                </li>
              </ul>
              <li>Les outils de statistiques</li>
              <ul>
                <li>
                  Matomo :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/matomo-org/matomo"
                  >
                    github.com/matomo-org/matomo
                  </a>
                </li>
                <li>
                  Shynet :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/milesmcc/shynet"
                  >
                    github.com/milesmcc/shynet
                  </a>
                </li>
              </ul>
              <li>Les sites hébergés par Libreon</li>
              <ul>
                <li>
                  Wordpress :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/WordPress/WordPress"
                  >
                    github.com/WordPress/WordPress
                  </a>
                </li>
              </ul>
              <li>Le serveur web</li>
              <ul>
                <li>
                  Traefik :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/traefik/traefik"
                  >
                    github.com/traefik/traefik
                  </a>
                </li>
              </ul>
              <li>Système d'exploitation et outil de déploiment</li>
              <ul>
                <li>
                  Debian :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://sources.debian.org/"
                  >
                    sources.debian.org/
                  </a>
                </li>
                <li>
                  Docker :{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.docker.com/community/open-source"
                  >
                    docker.com/community/open-source
                  </a>
                </li>
              </ul>
            </ul>
          </Container>
        </Container>
      </>
    );
  }
}
