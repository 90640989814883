import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class LibreonHelmet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const t = this.props.t;

    return (
      <Helmet>
        <title>{this.props.title}</title>
        <link rel="canonical" href="https://libreon.fr/" />
        <meta name="description" content={this.props.description} />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="fr" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={this.props.title} />
        <meta property="og:description" content={this.props.description} />
        <meta property="og:url" content="https://libreon.fr/" />
        <meta property="og:site_name" content="Libreon" />
      </Helmet>
    );
  }
}
