import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Jumbotron,
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaRedo } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js/pure";
import qs from "qs";

import hostingImg from "../img/Web_hosting_PNG.png";
import servicesImg from "../img/security_PNG.png";
import peopleImg from "../img/people_PNG.png";
import LibreonHelmet from "../components/libreon-helmet";
import ErrorDiv from "../components/error";
import Utils from "../classes/utils";

export default class Adhesion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      address: "",
      addressCompl: "",
      zipcode: "",
      city: "",
      email: "",
      acceptOptionalDonation: false,
      donation: "",
      newsletterRequest: false,
      acceptCotisation: true,
      error: false,
      success: false,
      waiting: false,
      errorMessage: "",
      successMessage: "",
      displaySuccessCheckout: false,
      startDate: new Date(Date.now()),
      endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    };
    this.utils = new Utils();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
  }

  getServerTime() {
    const requestUrl = this.props.config.apiUrl + "/dates";
    axios
      .get(requestUrl)
      .then((response) => {
        this.setState({
          startDate: new Date(response.data.start_date),
          endDate: new Date(response.data.end_date),
        });
      })
      .catch((error) => {
        let message;
        if (error.response) {
          // manage errors
        } else if (error.request) {
          console.log("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.log("Error", error);
        }
        this.setState({
          error: true,
          waiting: false,
          errorMessage: message,
        });
      });
  }

  componentDidMount() {
    this.getServerTime();

    const params = qs.parse(this.props.location.search);
    if ("?success" in params) {
      const success = params["?success"];
      if (success == "true") {
        this.setState({
          displaySuccessCheckout: true,
        });
      }
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleChangeChk(event) {
    this.setState({
      [event.target.id]: !this.state[event.target.id],
    });
  }

  validateForm() {
    return (
      this.state.lname.length > 0 &&
      this.state.address.length > 0 &&
      this.utils.isValidCp(this.state.zipcode) &&
      this.state.city.length > 0 &&
      this.utils.validateEmail(this.state.email) &&
      this.state.acceptCotisation &&
      ((this.state.acceptOptionalDonation && this.state.donation.length > 0) ||
        !this.state.acceptOptionalDonation) &&
      !this.state.waiting
    );
  }

  handleSubmit(event) {
    const requestUrl = this.props.config.apiUrl + "/join-association";
    const data = {
      fname: this.state.fname,
      lname: this.state.lname,
      address: this.state.address,
      addressCompl: this.state.addressCompl,
      zipcode: this.state.zipcode,
      city: this.state.city,
      email: this.state.email,
      newsletterRequest: this.state.newsletterRequest,
      donation: this.state.donation ? parseInt(this.state.donation) : 0,
    };
    this.setState({
      waiting: true,
    });

    axios
      .post(requestUrl, data)
      .then((response) => {
        console.log(requestUrl, data);

        const stripePromise = loadStripe(this.props.config.stripePublicKey);

        stripePromise.then((stripe) => {
          stripe.redirectToCheckout({ sessionId: response.data.id });
        });
      })
      .catch((error) => {
        let message;
        if (error.response) {
          // manage errors
          message = error.response.data.message;
        } else if (error.request) {
          console.log("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.log("Error", error);
        }
        this.setState({
          error: true,
          waiting: false,
          errorMessage: message,
        });
      });
    event.preventDefault();
  }

  render() {
    if (this.state.displaySuccessCheckout) {
      return (
        <div>
          <LibreonHelmet
            title="Libreon - Merci"
            description="Merci pour votre adhésion !"
          />
          <Container className="my-big">
            <Container className="mt-5 mb-3 center">
              <h1 className="center mb-3">
                <span className="l-gradient">Merci !</span>
              </h1>
              <h2 className="white">Merci pour votre adhésion !</h2>
              <p>
                C'est grâce à votre soutien que l'association peut continuer à
                offrir ses services aux plus grand nombre !
              </p>
              <p>💙💚</p>
            </Container>
          </Container>
        </div>
      );
    }

    const dateOptions = { year: "numeric", month: "long", day: "numeric" };

    return (
      <>
        <LibreonHelmet
          title="Libreon - Formulaire d'adhésion"
          description="Adhérez à l'association Libreon en remplissant ce formulaire"
        />

        <Container className="my-big">
          {/* Register form */}

          <Col md={10}>
            <div className="mb-4">
              <h2 className="mb-3">
                <span className="l-gradient">Formulaire d'adhésion</span>
              </h2>
            </div>
            <Card>
              <Container className="py-5">
                <div className="center mb-4">
                  <h2>
                    Formulaire d'adhésion {this.state.startDate.getFullYear()}
                  </h2>
                </div>
                <div>
                  <Alert color="secondary">
                    Ce formulaire d'adhésion vous permet de régler votre
                    cotisation en ligne. Il utilise la solution de paiement{" "}
                    <a
                      href="https://www.stripe.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      stripe
                    </a>
                    . Si vous ne souhaitez pas passer par cette solution, vous
                    pouvez régler votre cotisation par chèque en téléchargeant
                    le{" "}
                    <a
                      href="https://cloud.libreon.fr/s/m74ddAQ4yFAY4Zi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      formulaire d'adhésion
                    </a>
                    .
                  </Alert>
                </div>
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <Label for="lname">Nom (ou raison sociale)</Label>
                        <Input
                          valid={
                            this.state.lname.length > 0 && this.state.lname != 0
                          }
                          invalid={
                            this.state.lname.length > 0 && this.state.lname == 0
                          }
                          onChange={this.handleChange}
                          value={this.state.lname}
                          type="text"
                          name="lname"
                          id="lname"
                          placeholder="Nom"
                        />
                      </Col>
                      <Col md={6}>
                        <Label for="fname">Prénom</Label>
                        <Input
                          valid={
                            this.state.fname.length > 0 && this.state.fname != 0
                          }
                          onChange={this.handleChange}
                          value={this.state.fname}
                          type="text"
                          name="fname"
                          id="fname"
                          placeholder="Prénom"
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label for="fname">Adresse</Label>
                    <Input
                      valid={
                        this.state.address.length > 0 && this.state.address != 0
                      }
                      invalid={
                        this.state.address.length > 0 && this.state.address == 0
                      }
                      onChange={this.handleChange}
                      value={this.state.address}
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Adresse"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      valid={
                        this.state.addressCompl.length > 0 &&
                        this.state.addressCompl != 0
                      }
                      onChange={this.handleChange}
                      value={this.state.addressCompl}
                      type="text"
                      name="addressCompl"
                      id="addressCompl"
                      placeholder="Complément d'adresse (facultatif)"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <Label for="zipcode">Code postal</Label>
                        <Input
                          valid={
                            this.state.zipcode.length > 0 &&
                            this.utils.isValidCp(this.state.zipcode)
                          }
                          invalid={
                            this.state.zipcode.length > 0 &&
                            !this.utils.isValidCp(this.state.zipcode)
                          }
                          onChange={this.handleChange}
                          value={this.state.zipcode}
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          placeholder="CP"
                        />
                      </Col>
                      <Col md={6}>
                        <Label for="city">Ville</Label>
                        <Input
                          valid={
                            this.state.city.length > 0 && this.state.city != 0
                          }
                          invalid={
                            this.state.city.length > 0 && this.state.city == 0
                          }
                          onChange={this.handleChange}
                          value={this.state.city}
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Ville"
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      valid={
                        this.state.email.length > 0 &&
                        this.utils.validateEmail(this.state.email)
                      }
                      invalid={
                        this.state.email.length > 0 &&
                        !this.utils.validateEmail(this.state.email)
                      }
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="chunkylover53@aol.com"
                    />
                    <FormFeedback>email invalide</FormFeedback>
                  </FormGroup>

                  <FormGroup className="mb-2" check>
                    <Label check>
                      <Input
                        onChange={this.handleChangeChk}
                        id="newsletterRequest"
                        checked={this.state.newsletterRequest}
                        type="checkbox"
                      />{" "}
                      Je souhaite m'inscrire à la newsletter (nouvelles de
                      l'association et articles de{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://blog.libreon.fr"
                      >
                        blog
                      </a>
                      )
                    </Label>
                  </FormGroup>

                  <p className="dark-text mt-5">
                    J'adhère à l’association Libreon pour la période du{" "}
                    {this.state.startDate.toLocaleDateString(
                      "fr-FR",
                      dateOptions
                    )}{" "}
                    au{" "}
                    {this.state.endDate.toLocaleDateString(
                      "fr-FR",
                      dateOptions
                    )}{" "}
                    et verse la somme de :
                  </p>

                  <FormGroup className="mb-2" check>
                    <Row>
                      <Col md={5}>
                        <Label check>
                          <Input
                            disabled={true}
                            required={true}
                            onChange={this.handleChangeChk}
                            id="acceptCotisation"
                            checked={this.state.acceptCotisation}
                            type="checkbox"
                          />{" "}
                          Cotisation annuelle : 12 €
                        </Label>
                      </Col>
                      <Col md={3}>
                        <Label check>
                          <Input
                            onChange={this.handleChangeChk}
                            id="acceptOptionalDonation"
                            checked={this.state.acceptOptionalDonation}
                            type="checkbox"
                          />{" "}
                          Don facultatif :
                        </Label>
                      </Col>
                      <Col md={3}>
                        <Input
                          valid={this.state.donation.length > 0}
                          invalid={this.state.donation < 0}
                          onChange={this.handleChange}
                          value={this.state.donation}
                          type="number"
                          name="donation"
                          id="donation"
                          placeholder=""
                        />
                      </Col>
                      <Col md={1}>€</Col>
                    </Row>
                  </FormGroup>

                  <p className="dark-text">
                    <span className="bold">Total :</span>{" "}
                    {12 +
                      (this.state.donation > 0
                        ? parseInt(this.state.donation)
                        : 0)}{" "}
                    €
                  </p>

                  <div className="mt-3">
                    <Button
                      disabled={
                        this.state.waiting ? true : !this.validateForm()
                      }
                      className="btn-nahibu-simple"
                    >
                      Régler
                    </Button>
                  </div>

                  <div className="my-3">
                    <Jumbotron className="py-4">
                      <p className="dark-text">
                        Après validation du formulaire, vous allez être redirigé
                        vers une page de paiement afin de vous acquitter de
                        votre cotisation.
                      </p>
                    </Jumbotron>
                  </div>

                  <div className="mt-2">
                    <ErrorDiv
                      error={this.state.error}
                      message={this.state.errorMessage}
                    />
                    {this.state.success ? (
                      <Alert color="success">{this.state.successMessage}</Alert>
                    ) : null}
                  </div>
                </Form>
              </Container>
            </Card>
          </Col>
        </Container>
      </>
    );
  }
}
