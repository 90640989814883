export default class Utils {
  isUrl(s) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    return regexp.test(s);
  }

  validateEmail(email) {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  stripUsername(username) {
    return username
      .replace(/[^0-9a-z-A-Z]/g, "")
      .replace(/ +/, "")
      .toLowerCase();
  }

  validateUsername(username) {
    const re = /^[0-9a-z-A-Z]+$/i;
    return re.test(String(username).toLowerCase()) && username.length > 2;
  }

  isValidCp(cp) {
    let re = /^\d{5}$|^\d{5}-\d{4}$/;
    return re.test(String(cp).toLowerCase());
  }

  isValidTel(tel) {
    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(String(tel).toLowerCase());
  }

  objectHaveKeys(obj, level, ...rest) {
    if (obj === undefined) return false;
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
    return this.objectHaveKeys(obj[level], ...rest);
  }

  parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  randomString(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  }

  objectsEqual(object1, object2) {
    return (
      Object.keys(object1).length == Object.keys(object2).length &&
      Object.keys(object1).every((p) => object1[p] === object2[p])
    );
  }

  arraysOfObjectsEqual(array1, array2) {
    return (
      array1.length === array2.length &&
      array1.every((o, idx) => this.objectsEqual(o, array2[idx]))
    );
  }
}
