import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardDeck,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaRedo } from "react-icons/fa";

import hostingImg from "../img/Web_hosting_PNG.png";
import servicesImg from "../img/security_PNG.png";
import peopleImg from "../img/people_PNG.png";
import LibreonHelmet from "../components/libreon-helmet";
import ErrorDiv from "../components/error";
import Utils from "../classes/utils";

export default class Cgu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <LibreonHelmet
          title="Libreon - CGU"
          description="Conditions générales d'utilisation des services Libreon"
        />
        <Container className="my-big">
          <Container className="mt-5 mb-3">
            <div className="anchor" id="services"></div>
            <h1 className="center mb-3">
              <span className="l-gradient">
                Conditions générales d'utilisation
              </span>
            </h1>
            <h2 className="white">Qu’est-ce que Libreon ?</h2>
            <p>
              Libreon est une association loi 1901 qui a pour but la promotion
              de l'usage de solutions libres en alternative aux services
              propriétaires détenus par de grandes entreprises.
            </p>
            <p>
              Dans ce but, Libreon propose des services en ligne libres,
              éthiques, décentralisés et solidaires afin de permettre aux
              personnes d’avoir accès à des alternatives aux produits des
              grandes entreprises du secteur informatique qui font payer leurs
              services avec nos libertés.
            </p>
            <p></p>

            <h2 className="white">Hébergement</h2>
            <p>
              L’infrastructure physique est hébergée chez Scaleway, au sein du
              datacenter d'Amsterdam AMS1. L’Adresse du Centre Administration
              Scaleway est : Scaleway SAS - BP 438 - 75366 Paris CEDEX 08 - RCS
              Paris B 433 115 904
            </p>
            <p>
              Les sauvegardes de l’infrastructure sont hébergées chez Time4VPS
              au sein du datacenter de Vilnius en Lithuanie. L’Adresse du siège
              social est : J. Kubiliaus st. 6 08234 Vilnius Lithuania.
            </p>

            <h2 className="white">Utilisation des services</h2>
            <h3 className="white">Votre responsabilité</h3>
            <ul>
              <li>
                L’utilisation du service se fait à vos propres risques. Le
                service est fourni tel quel.
              </li>
              <li>
                Vous ne devez pas modifier un autre site afin de signifier
                faussement qu’il est associé avec les services Libreon.
              </li>
              <li>
                Les comptes ne peuvent être créés et utilisés que par des
                personnes physiques. Les comptes créés par les robots ou autres
                méthodes automatisées pourront être supprimés sans mise en
                demeure préalable. L’ouverture d’un compte affilié à un robot
                devra faire l’objet d’une demande préalable et ne pourra être
                effectué qu’après notre accord exprès.
              </li>
              <li>
                Vous êtes responsable de la sécurité de votre compte et de votre
                mot de passe. L’équipe Libreon ne peut pas et ne sera pas
                responsable de toutes pertes ou dommages résultant de votre
                non-respect de cette obligation de sécurité.
              </li>
              <li>
                Vous êtes responsable de tout contenu affiché et de l’activité
                qui se produit sous votre compte.
              </li>
              <li>
                Vous ne pouvez pas utiliser le service à des fins illégales ou
                non autorisées.
              </li>
              <li>
                Vous ne devez pas transmettre des vers, des virus ou tout autre
                code de nature malveillante.
              </li>
              <li>Vous ne devez pas transgresser les lois de votre pays.</li>
              <li>
                Vous ne pouvez pas vendre, échanger, revendre, ou exploiter dans
                un but commercial non autorisé un compte du service utilisé.
              </li>
            </ul>
            <p>
              Le non-respect de l’une de vos obligations entraînera la
              résiliation de votre compte. Votre responsabilité pourra être
              engagée en cas de manquement à l’une de vos obligations ayant
              causé un préjudice aux services Libreon.
            </p>

            <h3 className="white">Résponsabilité de Libreon</h3>
            <p>
              Libreon a la qualité d’hébergeur par application des dispositions
              de l’article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la
              confiance dans l’économie numérique. À ce titre Libreon ne peut
              pas être tenue responsable pour les contenus publiés sur ses
              services, sauf en cas de non retrait sur signalement de contenus
              illicites, tel que le prévoit la loi.
            </p>
            <ul>
              <li>
                Libreon ne peut être tenue responsable que du contenu qu’elle a
                elle-même édité.
              </li>
              <li>
                Libreon ne peut être tenue responsable pour les contenus ou les
                liens publiés par les utilisateurs et utilisatrices des services
                Libreon.
              </li>
              <li>
                Vous comprenez que la mise en ligne du service ainsi que de
                votre contenu implique une transmission (en clair ou chiffrée,
                suivant les services) sur divers réseaux. Vous comprenez que la
                mise en ligne de contenu sur certains services implique une
                publication accessible à toutes et tous.
              </li>
              <li>
                Libreon ne garantit pas que :
                <ul>
                  <li>les services répondrons à vos besoins spécifiques ;</li>
                  <li>
                    les services serons ininterrompus ou exempts de bugs, et que
                    les erreurs seront corrigées.
                  </li>
                </ul>
              </li>
              <li>
                Vous comprenez et acceptez que Libreon ne peut être tenu
                responsable de tous dommages directs, indirects, ou fortuits,
                comprenant les dommages pour perte de profits, de clientèle,
                d’accès, de données ou d’autres pertes intangibles et qui
                résulteraient de :
                <ul>
                  <li>
                    l’utilisation ou de l’impossibilité d’utiliser le service ;
                  </li>
                  <li>
                    l’accès non autorisé ou altéré de la transmission des
                    données ;
                  </li>
                  <li>
                    les déclarations ou les agissements d’un tiers sur le
                    service ;
                  </li>
                  <li>la résiliation de votre compte ;</li>
                  <li>toute autre question relative au service.</li>
                  <li>
                    Libreon se réserve le droit de poursuivre toute personne
                    physique ou morale pour tout fait de nature à lui porter
                    préjudice.
                  </li>
                </ul>
              </li>
              <li>
                L’échec de l’équipe de Libreon à exercer ou à appliquer tout
                droit ou disposition des conditions générales d’utilisation ne
                constitue pas une renonciation à ce droit ou à cette
                disposition. Les conditions d’utilisation constituent
                l’intégralité de l’accord entre vous et Libreon et régissent
                votre utilisation du service, remplaçant tous les accords
                antérieurs entre vous et Libreon (y compris les versions
                précédentes des conditions générales d’utilisation). Les
                questions sur les conditions de service doivent être envoyées
                via le formulaire de contact.
              </li>
            </ul>

            <h3 className="white">Modifications des services Libreon</h3>
            <ul>
              <li>
                L’équipe Libreon se réserve le droit, à tout moment, de modifier
                ou d’interrompre, temporairement ou définitivement, le service
                avec ou sans préavis.
              </li>
              <li>
                L’équipe Libreon ne sera pas responsable envers vous ou tout
                tiers pour toute modification, suspension ou interruption du
                service.
              </li>
              <li>
                L’équipe Libreon peut notifier certains changements liés aux
                services ou aux conditions d’usages, voire inviter les personnes
                qui utilisent ces services à participer à une évolution des
                services et conditions d’usage. Si vous souhaitez être avertis
                de ces changements, vous pouvez vous inscrire à la liste
                d’informations de Libreon.
              </li>
            </ul>

            <h3 className="white">Droit d’auteur sur les contenus</h3>
            <ul>
              <li>
                Vous ne pouvez pas envoyer, télécharger, publier, distribuer,
                diffuser tout contenu illégal, diffamatoire, harcelant, abusif,
                frauduleux, contrefait, obscène ou autrement répréhensible.
              </li>
              <li>
                Nous ne revendiquons aucun droit sur vos données : textes,
                images, sons, vidéos, ou tout autre élément, que vous
                téléchargez ou transmettez depuis votre compte.
              </li>
              <li>
                Nous n’utiliserons pas votre contenu pour un autre usage que de
                vous fournir le service.
              </li>
              <li>
                Vous ne devez pas télécharger ou rendre disponible tout contenu
                qui porte atteinte aux droits d’un tiers, personne physique ou
                morale
              </li>
              <li>
                Nous nous réservons le droit de supprimer tout contenu nous
                paraissant non pertinent pour l’usage du service, selon notre
                seul jugement.
              </li>
              <li>
                Nous pouvons, si nécessaire, supprimer ou empêcher la diffusion
                de tout contenu sur le service qui ne respecterait pas les
                présentes conditions.
              </li>
              <li>
                Conformément à leurs obligations en qualité d’hébergeur, Libreon
                procédera au retrait de tout contenu dès le moment où elles ont
                eu connaissance de son caractère manifestement illicite. Pour
                signaler tout contenu manifestement illicite ou pour signaler un
                abus : le formulaire de contact.
              </li>
            </ul>

            <h3 className="white">Édition et partage de données</h3>
            <p>
              Les fichiers que vous créez avec le service peuvent être — si vous
              le souhaitez — lus, copiés, utilisés et redistribués par des gens
              que vous connaissez ou non. Le cas échéant, en rendant publiques
              vos données, vous reconnaissez et acceptez que toute personne
              utilisant ce site web puisse les consulter sans restriction.
              L’équipe Libreon ne peut être tenue responsable de tout problème
              résultant du partage ou de la publication de données entre
              utilisatrices et utilisateurs.
            </p>

            <p></p>

            <h3 className="white">Résiliation</h3>
            <p>
              L’équipe Libreon, à sa seule discrétion, a le droit de suspendre
              ou de résilier votre compte et de refuser toute utilisation
              actuelle ou future du service. Cette résiliation du service
              entraînera la désactivation de l’accès à votre compte, et dans la
              mesure du possible la restitution de tous vos contenus. Libreon
              n’a aucune obligation de résultat quant à la restitution de
              l’ensemble des contenus et données.
            </p>

            <p>
              L’équipe Libreon se réserve le droit de refuser le service à toute
              personne qui en fait la demande, sans qu’elle ait à exposer un
              quelconque motif.
            </p>

            <p>
              L’équipe Libreon se réserve également le droit de résilier votre
              compte s’il est présumé abandonné, après l’expiration d’un délai
              de 8 jours suivant demande par courriel de justifier de
              l’utilisation du compte.
            </p>

            <h3 className="white">Données à caractère personnel</h3>

            <p>
              Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978
              relative à l’informatique, aux fichiers et aux libertés, dans sa
              version issue de la loi n°2018-493 du 20 juin 2018 et de son
              décret d’application, l’équipe Libreon garantit aux utilisateurs
              et utilisatrices un droit d’opposition, d’accès et de
              rectification sur les données nominatives le ou la concernant.
              Pour exercer tout droit relatif à vos données à caractère
              personnel, vous pouvez utiliser le formulaire de contact.
            </p>

            <ul>
              <li>
                Pour utiliser les services Libreon, vous devez créer un compte
                et certaines informations personnelles sont demandées : nom,
                prénom, adresse courriel valide et mot de passe qui est utilisé
                pour protéger votre compte contre tout accès non autorisé.
              </li>
              <li>
                Tout comme d’autres services en ligne, le site Libreon peut
                enregistrer automatiquement, en fonction du service, certaines
                informations concernant votre utilisation du service telles que
                l’activité du compte (exemple : espace de stockage occupé,
                nombre d’entrées, mesures prises), les données affichées ou
                cliquées (exemple : liens, éléments de l’interface), et d’autres
                informations pour vous identifier (exemple : cookie de session,
                type de navigateur, adresse IP, date et heure de l’accès, URL de
                référence). Ces données ne sont ni vendues, ni transmises à des
                tiers, et ne le seront jamais.
              </li>
            </ul>

            <h3 className="white">Cookies (témoins de connexion)</h3>
            <p>
              Les services Libreon déposent sur l’ordinateur des utilisatrices
              et utilisateurs des cookies strictement nécessaires au
              fonctionnement du service et aucun pistage n’est réalisé au moyen
              de ces cookies.
            </p>

            <h3 className="white">Obligation de communication de données</h3>
            <p>
              Nous ne communiquerons des données concernant les utilisateurs et
              utilisatrices des services Libreon que dans les cas où cette
              communication est rendue obligatoire par les dispositions
              législatives et réglementaires.
            </p>

            <h3 className="white">Suppression de compte et de données</h3>
            <p>
              Une personne peut demander à tout moment la suppression de son
              compte et des données associées et l’équipe Libreon s’engage à
              répondre à la demande en tenant compte des limites possibles du
              service en question.
            </p>

            <h3 className="white">
              Clause attributive de juridiction et loi applicable
            </h3>
            <p>
              Les services et leurs conditions générales d’utilisation sont
              régis par la loi française, quel que soit le lieu d’utilisation.
              En cas d’échec des solutions amiables ou dans l’absence de leur
              recherche, les tribunaux français du ressort de la Cour d’appel de
              Paris sont seuls compétents pour répondre de tout différend né des
              présentes dispositions.
            </p>
          </Container>
        </Container>
      </>
    );
  }
}
