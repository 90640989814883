import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaCheck, FaTimes } from "react-icons/fa";

import hostingImg from "../img/Web_hosting_PNG.png";
import servicesImg from "../img/security_PNG.png";
import peopleImg from "../img/people_PNG.png";
import chatonsImg from "../img/logo_chatons.png";
import LibreonHelmet from "../components/libreon-helmet";
import ServicesList from "../data/services.json";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const numberOfServicesToDisplay = 5;
    const uniqueServicesList = [
      ...new Map(
        [
          ...Object.values(ServicesList)[0],
          ...Object.values(ServicesList)[1],
        ].map((item) => [item.title, item])
      ).values(),
    ];
    return (
      <>
        <LibreonHelmet
          title="Libreon - Services en ligne et hébergement associatif"
          description="Libreon : services en ligne, hébergement associatif, libre et gratuit à Rennes"
        />
        <Container fluid className="full-page-center background-image">
          <Container className="center content">
            <h1 className="mb-3 white">
              Services en ligne et hébergement associatif
            </h1>
            <h4 className="mb-4 white"></h4>
            <div className="mb-4">
              <p className="white">
                Libreon est une association rennaise promouvant l'usage de
                solutions d'hébergements alternatives
              </p>
            </div>
            <div className="mb-4">
              <Row>
                <Col
                  className="px-1"
                  style={{ "text-align": "right" }}
                  md={6}
                  xs={6}
                >
                  <Link to="/association">
                    <Button size="" className="l-gradient-bg">
                      L'association
                    </Button>
                  </Link>
                </Col>
                <Col
                  className="px-1"
                  style={{ "text-align": "left" }}
                  md={6}
                  xs={6}
                >
                  <Link to="/services">
                    <Button size="" className="l-gradient-bg">
                      Les services
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="mt-4">
              {/* Animated scroll icon */}
              <div className="desktop-only scroll-downs desktop-only mt-5">
                <div className="mousey">
                  <div className="scroller"></div>
                </div>
              </div>
            </div>
          </Container>
        </Container>

        <Container fluid className="">
          <Container className="my-big">
            <h2 className="white small-underline mb-5">L'association</h2>
            <Row className="">
              <Col md={6} className="py-3">
                {/* Intro */}
                <p>
                  Libreon est une association rennaise promouvant l'utilisation
                  de logiciels libres en alternative aux services propriétaires
                  détenus par de grandes entreprises.
                </p>
                {/* Hébergement de services */}
                <p>
                  Pour cela, Libreon propose un ensemble de services permettant
                  d'héberger vos données. Ces services sont basée sur des
                  logiciels libres.
                </p>
                {/* Ethique */}
                <p>
                  Libreon ne revend pas vos données, et ne se les approprie pas.
                  Vous restez propriétaire des données que vous hébergez sur la
                  plateforme.
                </p>
                {/* Chatons */}
                <p>
                  Libreon est membre des{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://chatons.org"
                  >
                    CHATONS
                  </a>
                  , et respecte la{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://chatons.org/fr/charte-et-manifeste"
                  >
                    charte du collectif
                  </a>
                  .
                </p>
                <Link to="/association">
                  <Button size="sm" className="l-gradient-bg">
                    En savoir plus
                  </Button>
                </Link>
              </Col>
              <Col md={6} className="center py-3">
                <LazyLoadImage
                  className=""
                  alt="l'association"
                  src={chatonsImg}
                  width="70%"
                />
              </Col>
            </Row>
          </Container>
        </Container>

        <Container fluid className="">
          <Container className="my-big">
            <h2 className="white small-underline mb-5">Les services</h2>
            <Row className="flex-column-reverse flex-lg-row">
              <Col md={6} className="py-3 center">
                <LazyLoadImage
                  className=""
                  alt="les services"
                  src={servicesImg}
                  width="70%"
                />
              </Col>
              <Col md={6} className="py-3">
                <p>
                  Libreon propose un ensemble de services. Ils sont tous basé
                  sur des logiciels open-source.
                </p>
                <ul>
                  {uniqueServicesList
                    .slice(0, numberOfServicesToDisplay)
                    .map((service, i) => (
                      <li key={i}>
                        <a target="_blank" rel="noreferrer" href={service.url}>
                          {service.title}
                        </a>{" "}
                        : {service.subtitle}.
                      </li>
                    ))}
                  <li>
                    Et d'autres services à découvrir{" "}
                    <Link to="/services">ici</Link> !
                  </li>
                </ul>
                <p>
                  Ces services sont utilisables gratuitement. Certains
                  necessitent un <Link to="/account">compte Libreon</Link>.
                </p>
                <Link to="/services">
                  <Button size="sm" className="l-gradient-bg">
                    En savoir plus
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container fluid className="">
          <Container className="my-big">
            <h2 className="white small-underline mb-5">L'hébergement</h2>
            <Row className="">
              <Col md={6} className="py-3">
                <p>
                  Vous recherchez un hébergement pour votre site internet ?
                  Libreon peux héberger votre site, et vous aider à son
                  installation et sa configuration.
                </p>
                <ul>
                  <li>Hébergement de votre site internet.</li>
                  <li>Sauvegarde quotidienne.</li>
                  <li>Statistiques respectueuses de vos visiteurs.</li>
                </ul>
                <p>
                  <Link to="/contact">Contactez nous</Link> afin de discuter de
                  votre projet.
                </p>
                {/*<Link to="/hebergement"><Button size="sm" className="l-gradient-bg">En savoir plus</Button></Link>*/}
              </Col>
              <Col md={6} className="py-3 center">
                <LazyLoadImage
                  className=""
                  alt="l'hébergement"
                  src={hostingImg}
                  width="70%"
                />
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
}
