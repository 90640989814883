import React from "react";
import ReactDOM from "react-dom";

import Routes from "./routes";

export default class Libreon extends React.Component {
  render() {
    return <Routes />;
  }
}

ReactDOM.render(<Libreon />, document.getElementById("root"));
