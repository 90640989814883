import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
} from "reactstrap";
import { Link } from "react-router-dom";
import Utils from "../classes/utils";
import axios from "axios";

import { FaCheck, FaTimes, FaBell } from "react-icons/fa";

export default class NewsletterInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.utils = new Utils();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  validateForm() {
    return this.utils.validateEmail(this.state.email);
  }

  handleSubmit(event) {
    const data = {
      email: this.state.email,
    };

    this.setState({
      waiting: true,
      error: false,
      success: false,
      waiting: true,
    });

    const requestUrl = this.props.config.apiUrl + "/register-newsletter";
    axios
      .post(requestUrl, data)
      .then((response) => {
        console.log(requestUrl, response.data);
        this.setState({
          email: "",
          success: response.data.success,
          error: !response.data.success,
          waiting: false,
        });
      })
      .catch((error) => {
        // manage errors
        let message;
        if (error.response) {
          console.error("Response error", error.response.data.data.message);
        } else if (error.request) {
          console.error("Request error", error.request);
          message = "Erreur réseau, veuillez réessayer plus tard";
        } else {
          console.error("Error", error.message);
        }

        this.setState({
          success: false,
          error: true,
          waiting: false,
        });
      });
    event.preventDefault();
  }

  render() {
    const t = this.props.t;

    return (
      <Form onSubmit={this.handleSubmit}>
        <InputGroup>
          <Input
            onChange={this.handleChange}
            bsSize="sm"
            type="email"
            name="email"
            id="email"
            value={this.state.email}
            placeholder="Entrez votre email"
          />
          <InputGroupAddon addonType="append">
            <Button
              disabled={this.state.waiting ? true : !this.validateForm()}
              size="sm"
              className=""
            >
              <FaBell /> S'abonner{" "}
              {this.state.error ? (
                <FaTimes color="#dc3545" />
              ) : this.state.success ? (
                <FaCheck color="#28a745" />
              ) : null}
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </Form>
    );
  }
}
