import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardDeck,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import update from "immutability-helper";
import sha1 from "js-sha1";
import { FaRedo } from "react-icons/fa";

import hostingImg from "../img/Web_hosting_PNG.png";
import servicesImg from "../img/security_PNG.png";
import peopleImg from "../img/people_PNG.png";
import LibreonHelmet from "../components/libreon-helmet";
import ErrorDiv from "../components/error";
import Utils from "../classes/utils";
import ServicesList from "../data/services.json";

export default class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <LibreonHelmet
          title="Libreon - Les services"
          description="Cloud, Chat, Git, tout les services Libreon sont accessible sur cette page"
        />
        <Container className="my-big">
          <Container className="mt-5 mb-3">
            <div className="anchor" id="services"></div>
            <h1 className="center mb-3">
              <span className="l-gradient">Les services</span>
            </h1>
            <p>
              L’intégralité des services proposés repose sur des solutions de
              logiciels libres, sans aucune exploitation des données, sans
              publicité et sans traçage.
            </p>
            <p></p>
            <h2 className="mb-3">
              <span className="l-gradient">Les services authentifiés</span>
            </h2>
            <p>
              Les services suivants necessitent un{" "}
              <Link to="/account">compte Libreon</Link>.
            </p>
            <Row md="3" sm="2" xs="1">
              {ServicesList.authenticated.map((service, i) => (
                <Col key={i} className="mb-4 d-sm-flex">
                  <Card className="">
                    <CardBody>
                      <CardTitle className="center" tag="h5">
                        {service.title}
                      </CardTitle>
                      <CardSubtitle tag="h6" className="center mb-2 text-muted">
                        {service.subtitle}
                      </CardSubtitle>
                      <CardText>{service.description}</CardText>
                      <div className="center">
                        <Button
                          target="_blank"
                          rel="noopener noreferrer"
                          href={service.url}
                          size="sm"
                          className="l-gradient-bg"
                        >
                          {service["link-text"]}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              <Col className="mb-4 d-sm-flex">
                <div className=" more-service">
                  <h5 className="grey center my-5">
                    Et d'autres services à venir
                  </h5>
                </div>
              </Col>
            </Row>

            <h2 className="mt-3 mb-3">
              <span className="l-gradient">Les services libres d'accès</span>
            </h2>
            <p>
              Ces services sont utilisable sans compte Libreon. N'hésitez pas à
              en abuser sans modération.
            </p>
            <Row md="3" sm="2" xs="1">
              {ServicesList.freeAccess.map((service, i) => (
                <Col key={i} className="mb-4 d-sm-flex">
                  <Card className="">
                    <CardBody>
                      <CardTitle className="center" tag="h5">
                        {service.title}
                      </CardTitle>
                      <CardSubtitle tag="h6" className="center mb-2 text-muted">
                        {service.subtitle}
                      </CardSubtitle>
                      <CardText>{service.description}</CardText>
                      <div className="center">
                        <Button
                          target="_blank"
                          rel="noopener noreferrer"
                          href={service.url}
                          size="sm"
                          className="l-gradient-bg"
                        >
                          {service["link-text"]}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              <Col className="mb-4 d-sm-flex">
                <div className=" more-service">
                  <h5 className="grey center my-5">
                    Et d'autres services à venir
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
}
