import React, { Component } from "react";
import { Alert } from "reactstrap";

export default class ErrorDiv extends Component {
  render() {
    return (
      <div style={this.props.mt ? { "margin-top": "1rem" } : {}}>
        <Alert
          isOpen={this.props.error}
          width="100%"
          color="danger"
          className={this.props.type == "bottom" ? "fixed-bottom" : ""}
        >
          <div className={this.props.type == "bottom" ? "container" : ""}>
            <i className="fas fa-exclamation-circle"></i>{" "}
            {this.props.message
              ? this.props.message
              : "Erreur inconnue, veuillez réessayer plus tard"}
          </div>
        </Alert>
      </div>
    );
  }
}
