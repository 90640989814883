import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import { FaFacebook, FaGitlab, FaCat, FaMastodon } from "react-icons/fa";

import { SiMatrix } from "react-icons/si";

import NewsletterInput from "./newsletter-input";

export default class LibreonFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      popoverOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onHoverLeave = this.onHoverLeave.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  onHover() {
    this.setState({
      popoverOpen: true,
    });
  }

  onHoverLeave() {
    this.setState({
      popoverOpen: false,
    });
  }

  render() {
    return (
      <footer className="footer footer-content">
        <hr />
        <Container className="mb-5">
          <Row className="mb-3">
            <Col md={4} xs={6}>
              <h5 className="grey">Libreon</h5>
              <Link to="/association">
                <p className="white mb-0">L'association</p>
              </Link>
              <Link to="/services">
                <p className="white mb-0">Les services</p>
              </Link>
              <Link to="/contact">
                <p className="white mb-0">Contact</p>
              </Link>
              <Link to="/account">
                <p className="white mb-0">Demande de compte</p>
              </Link>
            </Col>
            <Col md={4} xs={6}>
              <h5 className="grey">L'association</h5>
              <Link to="/association">
                <p className="white mb-0">L'association</p>
              </Link>
              <Link to="/infra">
                <p className="white mb-0">L'infrastructure</p>
              </Link>
              <Link to="/cgu">
                <p className="white mb-0">Condition d'utilisation</p>
              </Link>
              <Link to="/mentions-legales">
                <p className="white mb-0">Mentions légales</p>
              </Link>
            </Col>

            <Col md={4} xs={12}>
              <h5 className="grey">Nous suivre</h5>
              <a
                className="px-1"
                target="_blank"
                rel="noreferrer"
                href="https://mastodon.libreon.fr/@libreon"
              >
                <FaMastodon size={25} style={{ fill: "#fff" }} />
              </a>
              <a
                className="px-1"
                target="_blank"
                rel="noreferrer"
                href="https://matrix.to/#/#libreon:libreon.fr"
              >
                <SiMatrix size={25} style={{ fill: "#fff" }} />
              </a>
              <a
                className="px-1"
                target="_blank"
                rel="noreferrer"
                href="https://gitlab.libreon.fr/libreon"
              >
                <FaGitlab size={25} style={{ fill: "#fff" }} />
              </a>
              <a
                className="px-1"
                target="_blank"
                rel="noreferrer"
                href="https://www.chatons.org/chatons/libreon"
              >
                <FaCat size={25} style={{ fill: "#fff" }} />
              </a>
              {/*<a className="px-1" target="_blank" rel="noreferrer" href="#"><FaFacebook size={25} style={{ fill: '#fff' }} /></a>*/}
              <h5 className="mt-2 grey">Newsletter</h5>
              <NewsletterInput config={this.props.config} />
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
