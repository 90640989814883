import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Container } from "reactstrap";
import update from "immutability-helper";
import { Helmet } from "react-helmet";

import CookieConsent from "react-cookie-consent";

import LibreonNavbar from "./components/navbar";
import LibreonFooter from "./components/footer";
import ScrollToTop from "./components/scroll-to-top";
import Utils from "./classes/utils";

import Home from "./routes/home";
import Contact from "./routes/contact";
import Account from "./routes/account";
import Services from "./routes/services";
import Association from "./routes/association";
import Infra from "./routes/infra";
import Cgu from "./routes/cgu";
import MentionsLegales from "./routes/mentions-legales";
import Adhesion from "./routes/adhesion";

export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        apiUrl: window.config ? window.config.apiUrl : "http://localhost:5000",
        stripePublicKey: window.config ? window.config.stripePublicKey : null,
      },
    };
    this.utils = new Utils();
  }

  componentDidMount() {}

  render() {
    return (
      <Router>
        <ScrollToTop />
        <LibreonNavbar />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route
            path="/contact"
            exact
            component={() => <Contact config={this.state.config} />}
          />
          <Route
            path="/account"
            exact
            component={() => <Account config={this.state.config} />}
          />
          <Route
            path="/services"
            exact
            component={() => <Services config={this.state.config} />}
          />
          <Route
            path="/association"
            exact
            component={() => <Association config={this.state.config} />}
          />
          <Route
            path="/infra"
            exact
            component={() => <Infra config={this.state.config} />}
          />
          <Route
            path="/cgu"
            exact
            component={() => <Cgu config={this.state.config} />}
          />
          <Route
            path="/mentions-legales"
            exact
            component={() => <MentionsLegales config={this.state.config} />}
          />
          <Route
            path="/adhesion"
            exact
            component={(props) => (
              <Adhesion {...props} config={this.state.config} />
            )}
          />
        </Switch>
        <LibreonFooter config={this.state.config} />
      </Router>
    );
  }
}
